import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Layout from "../components/layout"
import * as styles from "./index.module.scss"
import cover from "../../static/cover.png"

const Faq = ({ question, answer }) => {
  return (
    <div className={styles.faqItem}>
      <div className={styles.faqQuestion}>{question}</div>
      <div className={styles.faqAnswer}>{answer}</div>
    </div>
  )
}

class Index extends React.Component {
  componentDidMount() {
    const script = document.createElement("script")
    script.src = "https://paperform.co/__embed.min.js"
    script.async = true
    document.body.appendChild(script)
  }
  render() {
    return (
      <Layout title="Scholarship | Foundation 649">
        <Header />
        <div className={styles.hero}>
          <div className={styles.heroContent}>
            <div className={styles.name}>Foundation 649</div>
            is a 501c3 that discovers and empowers young, talented Asian
            Americans and Pacific Islanders who are actively elevating and
            serving their communities across the country.
            <br />
            <br />
            <p>2025 scholarship application is closed.</p>
          </div>
          <img className={styles.heroImg} src={cover} alt="Foundation 649" />
        </div>
        <div className={styles.scholarship}>
          <div className={styles.scholarshipContent}>
            <h3>2025 Scholarship Prompt</h3>
            <h2>
              Describe how your community uniquely contributes to the broader
              AAPI experience.
            </h2>
            <div className={styles.scholarshipDescription}>
              The Foundation 649 Scholarship is a grant of $4,000 over the
              course of 4 years that can be applied to college tuition and
              expenses. To maintain eligibility each year, there will be a brief
              check-in and renewal process at the end of each school year.
            </div>
            <div className={styles.scholarshipDetailSection}>
              <div className={styles.scholarshipDetailSectionTitle}>
                Details
              </div>
              <div className={styles.scholarshipDetailSectionContent}>
                <ul>
                  <li>Five recipients will be selected</li>
                  <li>
                    Each recipient will receive an award of $4,000 USD spread
                    over 4 years
                  </li>
                  <li>
                    The award must be renewed each year to receive successive
                    funds (details to come)
                  </li>
                  <li>Application deadline is March 1, 2025</li>
                  <li>
                    10 entrants will be selected as finalists for video call
                    interviews by March 31, 2025
                  </li>
                  <li>Recipients are announced by April 15, 2025</li>
                </ul>
              </div>
            </div>
            <div className={styles.scholarshipDetailSection}>
              <div className={styles.scholarshipDetailSectionTitle}>
                Requirements
              </div>
              <div className={styles.scholarshipDetailSectionContent}>
                <ul>
                  <li>
                    Be a degree-seeking, full-time student enrolling in a U.S.
                    accredited university in Fall 2025 as a Freshman or
                    transferring from a community college
                  </li>
                  <li>Identify as Asian American / Pacific Islander</li>
                  <li>
                    Have a track record of involvement and interest in AAPI
                    communities, issues, or causes
                  </li>
                  <li>Have demonstrated financial need for the scholarship</li>
                  <li>
                    Be a US citizen, resident, or foreign national. Undocumented
                    students are also welcome to apply.
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.scholarshipDetailSection}>
              <div className={styles.scholarshipDetailSectionTitle}>
                Application
              </div>
              <div className={styles.scholarshipDetailSectionContent}>
                The application consists of a questionnaire and a short essay
                (1000 word maximum). We want to hear your stories - we encourage creativity and originality (this should not be a rehash of your resume)!
                Alternate media will also be accepted in place of the essay if you
                choose (e.g. film, photography, etc.). If you are selected as a
                recipient, with your permission, we plan to share your
                submission with our audiences.
                <br />
                <br />
                In addition, applicants should provide the FAFSA SAR (student
                aid report) that show their EFC (expected family contribution).
                If you are unable to provide this document, please provide
                substitute documentation and elaborate in the financial aid
                section.
                {/* <button className={styles.applyBtn}
                  data-paperform-id="nyessltl"
                  data-popup-button="1">
                  Apply Today
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.faq}>
          <div className={styles.faqContent}>
            <h2>FAQ</h2>
            <Faq
              question={
                "Is there a cutoff Expected Family Contribution or household income that determines eligibility?"
              }
              answer={
                "We recognize that applicants come from a variety of financial situations, and that neither the EFC or household income is a perfectly representative number. While they will factor heavily into our ultimate decision, we would also like to hear about additional financial context on you and will consider it as part of your application in a broader, holistic context."
              }
            />
            <Faq
              question={
                "Am I eligible if I am of ________ descent or if my parents are from ________?"
              }
              answer={
                "We recognize that there may be cases in which a candidate identifies as AAPI but don't fall neatly into a familial bucket, e.g. adoptees. Generally, if you are of AAPI descent and resonate with Foundation 649's mission, we encourage you to apply. However, don't hesitate to email us if you have specific eligibility questions."
              }
            />
            <Faq
              question={"Where do I find my FAFSA SAR?"}
              answer={
                "After you submit your Free Application for Federal Student Aid (FAFSA) form, you’ll get a Student Aid Report (SAR) that summarizes the information you provided on your FAFSA form. For more detailed instructions, please visit https://studentaid.gov/apply-for-aid/fafsa/review-and-correct/sar-student-aid-report."
              }
            />
            <Faq
              question={"What if I haven't received my FAFSA SAR yet?"}
              answer={
                "If for any reason you don't have a FAFSA, please upload income information, such as W2, 1099s, tax returns and elaborate in the question below. We may reach out for additional documentation."
              }
            />
            <div className={styles.otherQuestions}>
              Questions? Please contact us at{" "}
              <a href="mailto:team@foundation649.com">team@foundation649.com</a>
              .
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default Index
